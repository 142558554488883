@tailwind base;
@tailwind components;
@tailwind utilities;

#myskills {
  position: relative;
  height: auto;
  width: 90%;
  padding: 90px 0;
  display: flex; 
  justify-content: center; 
  flex-wrap: wrap; 
  flex-direction: column;
}

.container {
  padding: 0 1rem;
  margin-left: auto;
  margin-right: auto;

}
.skill {
  margin-bottom: 15px;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.skill-info { 
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.skill-meter {
  background-color: #ccc;
  border-radius: 40px;
  height: 10px;
  width: 100%;
}

.skill-label, .skill-percent {
  color: #000;
  font-family: arial;
  font-weight: bold;
  font-size: 12px;
  margin: 0;
  order: 1;
}

.skill-percent {
  font-size: 15px;
}

.skill-range {
  background-color: #fe3c3a;
  height: 100%;
  border-radius: 40px 0px 0px 40px;
}

.WordPress{
  border-radius: 40px 20px 20px 40px !important;
}

.alert.alert-success{
  color:#dafd54;
  font-size: 20px;
} 

.alert.alert-danger{
  color:#fe3c3a;
  font-size: 20px;
} 

.auto-type{
  position: relative;
  top: 0%;
  width: 100%;  
  font-size: 90px;
  white-space: nowrap;
  text-align: center;
  line-height:2;
}
.auto-type{
  font-size: 60px;
  color:#fe3c3a;
}

.auto-type-my{
  color:#fe3c3a;
  text-align: center;
  font-size: 30px;
  line-height: 2;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .auto-type {
    font-size: 30px; 
    line-height: 1.5; 
  }

  .auto-type-my {
    font-size: 25px; 
    line-height: 1.5;
  }
}


.scrollBorder {
  position: relative;
}

.scrollBorder::after {
  content: '';
  position: absolute;
 
  bottom: -10px;
  left: 0;
  width: 0;
  height: 10px; /* Adjust the height as needed */
  background-color: #fe3c3a; /* Change to the desired color */
  transition: width 1s; /* Adjust the duration as needed */
}
.filled-border-top-to-bottom:after {
  width: 100%;
}
.filled-border-bottom-to-top::after{
  width: 100%;
  right: 0;
  left: auto;
}

@media only screen and (max-width: 1023px) {


  .scrollBorderRight {
    position: relative;
  }
  
  .scrollBorderRight::after {
    content: '';
    position: absolute;
   
    bottom: 0px;
    left: 0;
    width: 0;
    height: 10px; /* Adjust the height as needed */
    background-color: #fe3c3a; /* Change to the desired color */
    transition: width 1s; /* Adjust the duration as needed */
  }

  .filled-border-top-to-bottom.scrollBorderRight::after {
    width: 100%;
  }
  .filled-border-bottom-to-top.scrollBorderRight::after{
    width: 100%;
    right: 0;
    left: auto;
  }


}
@media screen and (min-width:1024px) {

.scrollBorderRight {
  position: relative;
}

.scrollBorderRight::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 15px;
  height: 0;
  background-color: #fe3c3a;
  transition: height 0.5s, top 0.5s;
}

/* Fill from top to bottom */
.filled-border-top-to-bottom.scrollBorderRight::after {
  height: 100%;
  top: 0;
}

/* Fill from bottom to top */
.filled-border-bottom-to-top.scrollBorderRight::after {
  height: 100%;
  top: auto;
  bottom: 0;
}
}

.grecaptcha-badge{
  display: none !important;
}