.box-tab {
    overflow: hidden;
}

.box {
    color: #000;
    font-size: 16px;
    overflow: hidden;
    position: relative;
    max-width: 600px;
    transition: all 0.45s ease;
}

.box-container{
    opacity: 0;
    transform: translateX(-50%);
    transition: all 0.45s ease, transform 0.5s ease-out, opacity 0.5s ease-out;
}

.box-container.visible {
    opacity: 1;
    transform: translateX(0);
  }


.box img {
    width: 100%;
    height: 100%;
}

.box:before,
.box:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #dafd54;
    opacity: 0.5;
    -webkit-transition: all 0.45s ease;
    transition: all 0.45s ease;
}

.box:before {
    -webkit-transform: skew(30deg) translateX(80%);
    transform: skew(30deg) translateX(80%);
}

.box:after {
    -webkit-transform: skew(-30deg) translateX(70%);
    transform: skew(-30deg) translateX(70%);
}



.box .box-content {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 1;
    bottom: 0;
    padding: 20px 20px 20px 20px;

}

.box .box-content .content {
    padding: 20px 20px 20px 34%;
    transition-delay: 0.05s;
}

@media screen and (max-width:1000px) {
    .box .box-content {
        padding: 10px;
    }

    .box .box-content .content {
        padding: 10px;
    }

}

.box .box-content:before,
.box .box-content:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #dafd54;

    opacity: 0.5;
    z-index: -1;
    -webkit-transition: all 0.45s ease;
    transition: all 0.45s ease;
}

.box .box-content:before {
    -webkit-transform: skew(30deg) translateX(100%);
    transform: skew(30deg) translateX(100%);
}

.box .box-content:after {
    -webkit-transform: skew(-30deg) translateX(90%);
    transform: skew(-30deg) translateX(90%);
}

@media screen and (min-width:769px) {
    .box:hover:before,
    .box.hover:before {
        -webkit-transform: skew(30deg) translateX(10%);
        transform: skew(30deg) translateX(10%);
        -webkit-transition-delay: 0.05s;
        transition-delay: 0.05s;
    }
    
    .box:hover:after,
    .box.hover:after {
        -webkit-transform: skew(-30deg) translateX(0%);
        transform: skew(-30deg) translateX(0%);
    }



    .box:hover .box-content:before,
    .box.hover .box-content:before {
        -webkit-transform: skew(30deg) translateX(30%);
        transform: skew(30deg) translateX(30%);
        -webkit-transition-delay: 0.15s;
        transition-delay: 0.15s;
    }

    .box:hover .box-content:after,
    .box.hover .box-content:after {
        -webkit-transform: skew(-30deg) translateX(20%);
        transform: skew(-30deg) translateX(20%);
        -webkit-transition-delay: 0.1s;
        transition-delay: 0.1s;
    }
}


.box p {
    margin: 0;
    opacity: 0;
    letter-spacing: 1px;
}

.box h2 {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: #fff;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}

.box p {
    font-size: 18px;
}

@media screen and (min-width:769px) {

    .box:hover p,
    .box.hover p {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 0.9;
        transition-delay: 0.05s;
    }
}

@media screen and (max-width:768px) {

    .box h2 {
        font-size: 20px;
        margin-bottom: 0px;
    }

    .box p {
        font-size: 12px;
    }

    .box.open:before,
    .box.open:before {
        -webkit-transform: skew(0deg) translateX(0%);
        transform: skew(0deg) translateX(0%);
        -webkit-transition-delay: 0.05s;
        transition-delay: 0.05s;
    }

    .box.open:after {
        -webkit-transform: skew(0deg) translateX(0%);
        transform: skew(0deg) translateX(0%);

    }

    .bo.open .box-content:before,
    .box.open .box-content:before {
        -webkit-transform: skew(0deg) translateX(0%);
        transform: skew(0deg) translateX(0%);
        -webkit-transition-delay: 0.15s;
        transition-delay: 0.15s;

    }

    .box.open .box-content:after,
    .box.open .box-content:after {
        -webkit-transform: skew(0deg) translateX(0%);
        transform: skew(0deg) translateX(0%);
        -webkit-transition-delay: 0.1s;
        transition-delay: 0.1s;
        opacity: 0.5;
    }

    .box.open p {
        transform: translateY(0);
        opacity: 0.9;
        transition-delay: 0.05s;
    }
    .box.open  .op_cl{
        color: #000;
    }

}
